import React, { useState, useRef, useLayoutEffect } from "react"

import { Header, MobileHeader } from "./header"
import "./layout.css"
import Footer from "./footer"
import { isMobile } from "react-device-detect"

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [headerFooterHeight, setHFH] = useState(120)
  const headerRef = useRef()
  const footerRef = useRef()
  useLayoutEffect(() => {
    // console.log(headerRef, footerRef)
    setHFH(
      (footerRef.current ? (footerRef.current as any).clientHeight : 200) +
        (headerRef.current ? (headerRef.current as any).clientHeight : 200)
    )
  }, [])
  return (
    <>
      <main
        style={{
          minHeight: `calc(100vh - ${headerFooterHeight}px)`,
        }}
      >
        {children}
      </main>
      <Footer ref={footerRef} />
    </>
  )
}

export default Layout
