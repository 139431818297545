import React, { forwardRef } from "react"
import styled from "styled-components"

const Footer = forwardRef((props, ref: any) => (
  <AppFooter ref={ref}>
    <h3> Plains Dedicated Co-Op ™ member | Smart Way Transport Partner</h3>
    <img
      src="assets/smartway.png"
      title="SmartWay"
      style={{ width: "15em", padding: "10px", paddingTop: "20px" }}
    />
  </AppFooter>
))
console.log(`backgrounds by SVGBackgrounds.com.`)
const AppFooter = styled.footer`
  background-color: #2e2885;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  bottom: 5;
  width: 100%;
  h3 {
    padding: 20px;
    font-size: calc(8px + 1vw);
    margin: 0;
  }
`

export default Footer
